.header_wrap_div {
    height: 100vh;
    align-items: center;
    align-content: normal;
    justify-content: center;
    color: white;
    font-family: Vidaloka, serif !important;
    font-size: 120px;
    font-style: normal;
    font-weight: 200;
    padding-top: 10% !important;
    padding-bottom: 0 !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.header_wrap_div > div {
    padding-top: inherit;
}

.header_wrap_div > div > div {
    text-align: center !important;

}

.header_wrap_div > div > div > p {
        margin: auto;
}


.short_text_header {
    width: 100%;
    color: var(--off-heading, #FBFBFB) !important;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400;
    line-height: 28px;
    margin: 0 !important;
}


@keyframes impulsing {
    0%, 20% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-8px);
    }

}

.svg_header:hover {
    animation: impulsing 1.5s;
    box-shadow: 0 0 4px aliceblue;
}

.svg_container {
    padding-top: initial !important;
}

#new_future {
    color: var(--off-heading, #FBFBFB);
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 120px;
    font-style: normal;
    font-weight: 400;
    line-height: 144px; /* 120% */
    text-transform: uppercase;
}

#new_future {
    opacity: 0;
    animation: banner-header 1.0s .0s forwards;
}

.short_text_header {
    opacity: 0;
    animation: banner-header 1.0s .3s forwards;
}

.header_button_block {
    opacity: 0;
    animation: banner-header 1.0s .5s forwards;
}

@keyframes banner-header {

    0% {opacity: 0; transform: translateY(40px)}
    100% {opacity: 1; transform: translateY(0px)}
}


@media screen and (max-width: 1200px) {
    #new_future {
        font-size: 70px !important;
        line-height: 75px !important;
    }

    .header_wrap_div {
        align-content: space-evenly !important;
    }

}

@media screen and (min-width: 1200px) {
    .banner-content {
        padding: 0 !important;
    }
}


@media screen and (max-width: 480px) {
    #new_future {
        font-size: 50px !important;
        line-height: 75px !important;
    }

    .short_text_header {
        width: 100% !important;
        font-size: 12px !important;
    }
}







    /*
    background-image: url(https://w.forfun.com/fetch/76/763f1c0….jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;    */
