.projectLayout {
    background: var(--background-secondary, #F9FAF1) !important;
}

.fut_pr {
    color: var(--heading, #202020);
    text-align: center;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.dis_our{
    color: var(--heading, #202020);
    font-family: Vidaloka, serif !important;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 129.167% */
    text-transform: uppercase;

}

.title_image {
    color: var(--heading, #202020);
    font-family: Vidaloka, serif !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 141.667% */
    text-decoration-line: underline;
    text-transform: uppercase;
}

.desc_image {
    color: var(--text, #797978) !important;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}



.button_project {
    border-radius: 8px !important;
    border: 1.3px solid var(--primary, #BD9A68) !important;
    color: var(--primary, #BD9A68) !important;
    padding:  18px 40px  !important;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

@media screen and (min-width: 1200px) {
    .pr_img {
        width: 600px !important;
        height: 500px !important;
    }
}

@media screen and (max-width: 850px) {
    .pr_img {
        width: 550px !important;
        height: 470px !important;
    }
}

@media screen and (max-width: 650px) {
    .pr_img {
        width: 400px !important;
        height: 350px !important;
    }
}

@media screen and (max-width: 650px) {
    .pr_img {
        width: 300px !important;
        height: 200px !important;
    }

    .dis_our {
        font-size: 35px !important;
    }
}