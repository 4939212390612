.form_search_projects {
    box-shadow: 0 1px 5px 0 #8a8383 !important;
}

.button_search_projects > div {
    box-shadow: 0 1px 5px 0 #8a8383 !important;
    padding: 15px !important;
}

.image_of_projects {
    width: 400px !important;
    height: 325px !important;
}

.projects_card_text_1 {
    color: #202020 !important;
    text-align: center;
    font-family: Vidaloka, serif;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 32px !important;
}

.projects_card_text_2 {
    color: #797978 !important;
    text-align: center;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important; /* 142.857% */
    letter-spacing: .56px !important;
    text-transform: uppercase;
}

.btn_search_projects {
    width: -webkit-fill-available !important;
    color: white !important;
    padding: 15px !important;
}

.pagination_button_projects {
    padding: 0 !important;
    width: 56px !important;
    height: 56px !important;
    border-radius: 50% !important;
    border: 1px solid #BD9A68 !important;
    color: #BD9A68 !important;
    transition: .4s !important;
    margin: 5px !important;
}

.pagination_button_projects:hover {
    color: white !important;
    background: #BD9A68 !important;
}

.title_project {
    color: #BD9A68 !important;

    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0.56px !important;
    text-transform: uppercase;
}

.name_project {
    color: #202020 !important;
    font-family: Vidaloka, serif !important;
    font-size: 72px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 94px !important;
    text-transform: uppercase;
}

.description_project {
    color: #797978 !important;
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 28px !important;
}

.property_text_project {
    line-height: 25px !important;
}

.item_carusel {
    width: 100% !important;
    height: 740px;
}

.paper_carusel {
    height: 740px;
}

.blog_slider {
    margin: 0 10px !important;
    border-radius: 50px;
}

.property_text {
    color: #202020 !important;

    /* Button Text */
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px !important; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase !important;
}

.property_text_bold {
    color: #202020 !important;
    font-family: Vidaloka, serif !important;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 62px !important; /* 129.167% */
    text-transform: uppercase !important;
}

.property_text_1 {
    color:  #797978 !important;
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
}

.just_info_project_draft_img {
    width: -webkit-fill-available;
    height: auto;
}




