::-webkit-scrollbar-track {
	box-shadow: none;
	-webkit-box-shadow: none;
	background-color: #F9FAF1;
	border-radius: 10px;
}

::-webkit-scrollbar {
	width: 8px !important;
	background-color: #F9FAF1;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #bd9a68;
}

::selection {
  background-color: #514636;
  color: white;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
  
#root {
    height: 100vh;
    width: 100%;
}

.MuiInputBase-root {
    background-color: #212121 !important;
}

.MuiInputBase-root > fieldset {
    border: 1px solid #BD9A68 ;
}

.MuiInputBase-root:hover fieldset {
    border: 1.5px solid #BD9A68 !important;
}

.MuiInputBase-root > input:focus fieldset {
    border: 1.5px solid #BD9A68 !important;

}

.MuiInputBase-input:-webkit-autofill {
    box-shadow: 0 0 0 100px #BD9A68 inset !important;
    -webkit-text-fill-color: #fff !important;
}

.MuiInputBase-root, .MuiFormControl-root {
    border-radius: 6px !important;
}

input.MuiInputBase-input {
    margin: 6px 5px !important;
    padding: 4px !important;
}

.line {
    border-bottom: 1px solid rgba(128, 128, 128, 0.63) !important;
}