.footer_container {
    background: url("../../../../../assets/images/home/footer/background.png");
}

.footer_title_text {
    color: var(--off-heading, #FBFBFB);
    font-family: Vidaloka, serif !important;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 145.455% */
    text-transform: uppercase;
}

.reland_text {
    color: var(--primary, #BD9A68);
    font-family: Vidaloka, serif !important;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 39px; /* 130% */
    text-transform: uppercase;
}

.footer_small_text {
    color: #9B9B9B !important;

    /* Text */
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 186.667% */
}

.ft_lnk {
    cursor: pointer;
    margin-bottom: 4px;
}


.btn_footer {
    padding: 20px !important;
    border-radius: 0px 8px 8px 0px !important;
    background: var(--primary, #BD9A68) !important;
}

#footer_text_field {
    color: #828282 !important;
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 186.667% */
}

.item_textfield > div > div > fieldset.MuiOutlinedInput-notchedOutline {
    border-color: var(--primary, #BD9A68) !important;
    color: white !important;
}

.copyright {
    color: #9B9B9B;
    text-align: center;
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 186.667% */
}

.item_textfield > div > div {
    border-radius: 10px 0 0 10px !important;
    color: white !important;
}

.item_textfield > div > label {
    color: #828282 !important;
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 186.667% */
}

.icon_footer {
    border: 1px solid #BD9A68;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center !important;
    width: 40px !important;
    height: 40px !important;
    transition: .5s;
}


.icon_footer:hover {
    background: #BD9A68;
}

.icon_footer:hover, .icon_footer:hover > svg > path {
    fill: white !important;
}


@media screen and(max-width: 1200px) {
    .footer_newsletter_div {
        text-align: center !important;
        justify-content: center !important;
        margin-top: 10px !important;
    }
}


@media screen and (max-width: 1200px) {
    .footer_useful_links_div {
        justify-content: center;
    }

    .footer_useful_links_div > div {
        text-align: center ;
    }

    .footer_newsletter_div {
        margin-top: 20px !important;
        justify-content: center;
    }
}








