.team_root {
    background: #fff !important;
}

.img_our_team {
    border-radius: 16px;
    transition: .4s;
}

.img_our_team:hover {
    filter: brightness(90%) !important;
    cursor: pointer;
}

.our_team_wrap_div > div {
    position: absolute;
    color: white;
}

.prof_member{
    font-size: 12px !important;
}

.name_member, .prof_member{
    transition: .3s;
    opacity: 0;
}

.our_team_wrap_div:hover .name_member{
    transform: translateY(-20px);
    opacity: 1;
}

.our_team_wrap_div:hover .prof_member {
    transform: translateY(-5px);
    opacity: 1;
}
