.contact_root {
    background: white !important;
}

.title_contact {
    color: #BD9A68;
    text-align: center;

    /* Button Text */
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.location_contact {
    color: #202020 !important;
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 32px !important;
    text-transform: uppercase;
}

.contact_data {
    color: #797978 !important;
    text-align: center;
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 28px !important;
}

.frame_google_maps {
    flex-shrink: 0;
}

@media screen and (min-width: 300px) {
    .frame_google_maps { 
        width: 400px;
        height: 300px;
    }
}

@media screen and (min-width: 600px) {
    .frame_google_maps { 
        width: 700px;
        height: 600px;
    }
}

@media screen and (min-width: 1200px) {
    .frame_google_maps { 
        width: 1290px;
        height: 800px;
    }
}

frame {
    border: none !important;
}