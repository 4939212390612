.blog_slider {
    width: 95% !important;
    text-align: center !important;
    border-radius: 30px !important;
}

#root > div > div.project_list_site_part.MuiBox-root.css-ib9iq6 > div {
    width: 100% !important;
}

.img_slider {
    width: -webkit-fill-available !important;
    border-radius: 30px;
}