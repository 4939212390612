.overview_root {
    background: #fff !important;
}

.overview_title {
    color: #202020;
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 72px !important;
    font-style: normal;
    font-weight: 400 ;
    line-height: 94px; /* 130.556% */
    text-transform: uppercase;
}

.color_home_overview {
    color: black !important;
    vertical-align: super;
}

.overview_text-overview {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #797978;
    vertical-align: super;
}

.overview_big_image {
    width: inherit
}

.leadship_overview {
    color: #202020;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px !important;
    text-transform: uppercase;
}

.meet_with_overview {
    color:  #202020;
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 48px  !important;
    font-style: normal;
    font-weight: 400  !important;
    line-height: 62px !important; /* 129.167% */
    text-transform: uppercase;
}

.wrap_video {
    background-size: cover !important;
    background-position: center !important;
    width: 100% !important;
    height: 880px !important;
}

.jarallax-container {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    z-index: -100 !important;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);

}

.parallax-img {
    background-image: src('https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1c8jLN.img') !important;
    position: relative !important;
    z-index: 0 !important;
    background-size: cover !important;
    background-position: center !important;
    width: 100% !important;
    height: 880px !important;
}

@media screen and (max-width: 1200px) {
    .div_header_overview {
        justify-content: center !important;
    }

    .overview_who_we_are, .btn_overview_text_center{
        text-align: center !important;
    }
}

@media screen and (min-width: 1200px) {
    #jarallax-container-0 > iframe {
        width: 1920px !important;
    }
}

@media screen and (max-width: 550px) {
    .div_header_overview > img {
        width: 380px !important;
        height: 550px !important;
    }
}


.videoPlayer {
    position: fixed;
    inset: 0;
    width: auto;
    height: 1500.5px;
    max-width: none;
    max-height: none;
    pointer-events: none;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    margin: -365.75px 0px 0px;
    z-index: -1;
    transform: translate3d(0px, 67.2461px, 0px);
}