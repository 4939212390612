.message_not_found {
    font-family: Vidaloka !important;
    font-size: 72px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 94px !important;
    text-transform: uppercase !important;
}

.btn_not_found {
    background: #bd9a68 !important;
    color: white !important;
    text-transform: capitalize !important;
    padding: 5px 20px !important;

}

.error_page_container {
    background-image: url('../../../assets/images/not_found/Error.png');
    background-position: center !important;
}


@media screen and (max-width: 1400px) {
    .message_not_found {
        font-size: 50px !important;
        line-height: 74px !important;
    }   
}

@media screen and (max-width: 1000px) {
    .message_not_found {
        font-size: 40px !important;
        line-height: 64px !important;

    }   
}

@media screen and (max-width: 600px) {
    .message_not_found {
        font-size: 30px !important;
        line-height: 50px !important;

    }   
}