.wrap_container {
    background: #202020; 
    border-radius: 15px;
    height: -webkit-fill-available;
    overflow: auto
}

.wrap_container > form > div > div:nth-child(2), .wrap_container > form > div {
    height: 95%;
}

.stepper_box > div > span > span:first-child > svg {
    font-size: 60px !important;
}

.stepper_box > div > span > span:first-child > svg > text {
    font-size: 0.53rem !important;
}

.stepper_box > div > span > span:first-child > svg.Mui-active, .stepper_box > div > span > span:first-child > svg.Mui-completed, .step_completed > span > span:first-child > svg {
    color: #bd9a68 !important;
}

span.circle {
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 47px;
    height: 47px;
    border: 5px solid #bd9a68;
    border-radius: 50%;
}

span.circle_complete {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 2px solid #202020;
    border-radius: 50%;
}

.stepper_box > div > span > span:nth-child(2) > span {
    color: #fff !important;
}

.MuiStepConnector-horizontal {
    top: 30px !important;
    left: calc(-50% + 33px) !important;
    right: calc(50% + 33px) !important;
}

.MuiStepLabel-label {
    margin-top: 3px !important;
    font-size: 15px !important;
}

.not_able_block {
    pointer-events: none !important;
    filter: opacity(0.5);
  }