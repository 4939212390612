#title {
    color: var(--heading, #202020) !important;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.we_are_container{
    align-items: center !important;
    flex-flow: column !important;
    background: #FEFFFB;
}

.we_are_30_years {
    color: var(--heading, #202020);
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 48px !important;
    font-weight: 400;
    line-height: 62px;
    text-transform: uppercase;
}


#details {
    color: var(--text, #797978);
    text-align: center;
/* Text */
    font-family: Montserrat, serif !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 186.667% */
}

.more_about_button {
    border-radius: 8px !important;
    border: 1.3px solid var(--primary, #BD9A68) !important;
    color: var(--primary, #BD9A68) !important;
/* Button Text */
    font-family: Montserrat, serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
    padding: 18px 40px !important;
}

.div_image_we_are {
    text-align: center;
}


.video_blog > div > div > iframe {
    border-radius: 25px !important;
}

@media screen and (min-width: 300px) {


    .video_blog > div  {
        width: 350px !important;
        height: 250px !important;
    }

    .div_image_we_are > img {
        width: 50px !important;
    }
}

@media screen and (min-width: 600px) {
    .div_image_we_are > img {
        width: 70px !important;
    }

    .video_blog > div  {
        width: 450px !important;
        height: 300px !important;
    }
}

@media screen and (min-width: 800px) {
    .video_blog > div  {
        width: 650px !important;
        height: 400px !important;
    }

}

@media screen and (min-width: 1200px) {
    .video_blog > div  {
        width: 850px !important;
        height: 600px !important;
    }

    .div_image_we_are > img {
        width: 100px !important;
    }
}

@media screen and (min-width: 1920px) {
    .video_blog > div {
        width: 1290px !important;
        height: 800px !important;
    }

}


.vidaloka_text {
    color: var(--heading, #202020);
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 32px; /* 145.455% */
    text-transform: uppercase;
}

.montserrat_text {
    color: var(--text, #797978);
    text-align: center;
/* Text */
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 186.667% */
}

.descIcon:hover {
    transform: translateY(10px);
    transition: 1s;
}

.descIcon:hover + div > p {
    transition: .7s;
    color: #bd9a68;
}

.descIcon {
    transition: 1s;
}
