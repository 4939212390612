.name_project_text {
    color: white !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    font-size: 25px !important;
}

.name_project_short_desc {
    text-transform: capitalize !important;
    font-size: 18px !important;
    color: #ffffff9f !important;
}

.discount_price {
    color: #ffffff9f !important;
    font-size: 30px !important;
    margin-top: 15px !important;
    line-height: normal !important;
}

.discount_price > span:nth-child(2) {
    text-decoration: line-through solid red !important;
}

.discount_price > span:first {
    text-decoration: none !important;
}

.price_text {
    color: white !important;
    font-size: 30px !important;
    line-height: normal !important;
}

.price_text > span:nth-child(2) {
    font-size: 15px !important;
    color: #ffffff9f !important;
}

.dues_fee {
    margin-top: 10px !important;
    color: #ffffff9f !important;
}

.MuiTabs-flexContainer {
    justify-content: space-between !important;
}

.MuiTabs-scroller {
    border-bottom: 1px solid #7b7b7b !important;
}

.typography_flat_for_land {
    color: white;
}