.mission_root {
    background: #fff !important;
}

.create_greate_tomorrow_mission {
    color: #202020 !important;
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 48px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 62px !important; /* 129.167% */
    text-transform: uppercase;
}

.learn_more_link {
    color: #BD9A68 !important;
    transition: .4s;
}

.learn_more_link:hover {
    color: #282c34 !important;
}

.what_we_do_mission {
    background: #F9FAF1 !important;
}

.svg_mission {
    transition: 1s;
}

.svg_mission:hover {
    scale: 1.1 !important;
}


@media screen and (max-width: 510px) {
    .mission_img {
        width: 400px !important;
        height: 500px !important;

    }
}