form {
    width: 100%;
}

.wrap_tab_container {
    background: #202020 !important;
    overflow: auto;
}

.tab_text_field > div {
    border-radius: 10px !important;
    background: #292929 !important;
}

.grid_upload_inputs {
    position: relative;
}

.tab_text_field > div > input, .tab_text_field > div > textarea, .tab_text_field > div > select, .tab_text_field > div {
    color: #fff !important;
}

.tab_text_field {
    border-radius: 10px !important;
}

.tab_text_field > label > span,  tab_text_field > span {
    color: red !important;
}

.tab_text_field > div > fieldset, .tab_text_field > div > fieldset:target, .tab_text_field > fieldset  {
    border: 1px solid #bd9a68 !important;
}

.MuiFormControl-root > label, .tab_text_field > label, .tab_text_field > svg {
    color: #a0a0a0 !important;
}

.MuiFormControl-root > label > span {
    color: red !important;
}

.MuiMenu-paper {
    background: #161616 !important;
}

.MuiPaper-root > ul > li {
    color: white !important;
}

.MuiPaper-root > ul > li:hover {
    background:#bd9a68 !important;
}

.checkbox_field {
    color: #a0a0a0 !important;
}

.checkbox_field > span {
    padding: 0 !important;
}

.checkbox_field > span.MuiFormControlLabel-label {
    width: max-content;
    font-size: 16px !important;
}

.form_checkbox > span.MuiFormControlLabel-label {
    position: absolute !important;
    top: -23px;
    left: 0;
}

.checkbox_field > span > svg {
    font-size: 40px !important;
    fill: #bd9a68 !important;
}

.checkbox_field {
    position: relative !important;
    margin: 0px !important ;
}

.checkbox_is_urgent {
    align-items: flex-start !important;
}

.MuiFormControlLabel-labelPlacementTop {
    align-items: baseline;
}

.urgent_caption {
    padding-left: 0 !important;
}

.urgent_caption > p {
    font-size: 10px !important;
    padding-bottom: 5px !important;
    color: white;
}

.urgent_caption > p > span {
    color: red;
    font-size: 14px !important;
}

.wrap_accordion > div > div {
    border-radius: 10px 10px 0 0;
}


.choose_category_span {
    color: red;
    font-size: 23px !important;
}

.MuiAccordion-root {
    background: #292929 !important;
    color: white !important;
}

.MuiAccordion-root > div > div:nth-child(2) > svg {
    fill: #fff;
}

.MuiAccordionDetails-root {
    padding: 0 !important;
}

.ul_accordion {
    margin: 0 !important;
    padding: 10px ;
}

.ul_accordion:hover {
    background-color: #202020;
}

.ul_checked {
    background-color: #bd9a68 !important;
}

.summary_chosen {
    background-color: #bd9a6889 !important;
}

.media_upload_small_text {
    margin-top: 8px !important;
    color: #fff;
    font-size: 10px !important;
}

.span_linear_progress > span {
    font-size: 12px;
    color: #fff;
    margin-bottom: 3px;
}

.span_linear_progress > span:nth-child(2) {
    background-color: #bd9a6857;
}

.span_linear_progress > span:nth-child(2) > span {
    background-color: #bd9a68;
}

.btn_cert_and_draw_upload {
    text-transform: capitalize !important;
    background: #292929 !important;
    color: #ffffff !important;
    border: 1px solid #bd9a68 !important;
    border-radius: 15px !important;
}

.btn_cert_and_draw_upload:hover {
    background: #bd9a68 !important;
}

.map_desc_text {
    color: #fff !important;
    line-height: 15px !important;
}

.you_can_use_google_span {
    color: red;
    font-size: 14px;
}

.dropzone_media {
    border-color: #bd9a68 !important;
    min-height: 75px !important;
}

.dropzone_media > div:nth-child(2) > label > svg {
    fill: #bd9a68 !important;
    font-size: 30px !important;
}

.dropzone_certificate_and_drawing {
    cursor: pointer !important;
    border: 1px solid #bd9a68 !important;
    min-height: 45px !important;
    border-radius: 15px !important;
}

.dropzone_certificate_and_drawing:hover {
    background: #bd9a68 !important;
}

.dropzone_certificate_and_drawing > div:nth-child(2) > label {
    cursor: pointer !important;
    font-size: 13px !important;
    color: white !important;
}

.display_none {
    position: absolute;
    top: -400%;
}

button.slick-prev:before, button.slick-next:before {
    color: #bd9a68 !important;
}

.second_gallery_slider > button.slick-prev {
    left: -10px !important;
    z-index: 10;
}

.second_gallery_slider > button.slick-next {
    right: 0px !important;
    z-index: 10;
}

.second_gallery_slider > div.slick-list {
    margin-top: 7px !important;
}

.second_gallery_slider > div > div > div > div > div > img {
    width: 70px !important;
}


.hidden_second_gallery {
    display: none !important;
}


.view_btn_overview {
    text-transform: capitalize !important;
    background: #bd9a68 !important;
    border-radius: 5px !important;
    color: white !important;
    width: 45% !important;
    margin: 3px !important;
}

.btn_disabled {
    background: #161616 !important;
    
}

.circular_loader_publishing {
    width: 25px !important;
    height: 25px !important;
    color: #bd9a68 !important;
}

.preview_video {
    width: inherit !important;
}

.complete_add_svg {
    font-size: 250px !important;
    position: absolute;
    top: 40%;
    left: 50%;
    
}

.complete_add_svg > path {
    stroke-dasharray: 65;
    stroke-dashoffset: 65;
    stroke-width: 0.5px !important;

    stroke: #bd9a68;

    fill-opacity: 0;

    animation: draw 1.5s forwards;
  }
  
  @keyframes draw {
    to {
        stroke-dashoffset: 0 ;
        fill-opacity: 1;
        fill: #bd9a68 ;

    }
  }

  .next_btn, .prev_btn {
    background: #161616 !important;
    color: #bd9a68 !important;
  }

  .prev_btn:hover, .next_btn:hover {
    background: #bd9a68 !important;
    color: white !important;
  }


  .line_features {
    width: 100%;
    border: 1px solid #bd9a68;
    border-radius: 10px;
  }

  .btn_feature_option {
    transition: 0.1s !important;
    background: #202020 !important;
    color: #ffffff !important;
    border: 1px solid #bd9a68 !important;
    border-radius: 8px !important;
  }

  .btn_feature_option:hover {
    background: #bd9a68 !important;
    color: #ffffff !important;
  }


  .MuiInputBase-root > div > span {
    color: #a0a0a0;
  }

  .MuiInputBase-root > div > svg {
    color: #bd9a68 !important;
  }

  .MuiInputBase-root > div > button {
    color: #a0a0a0;
  }

  .MuiAutocomplete-paper {
    background: #161616 !important;
  }

  .MuiList-root > .Mui-selected, .MuiAutocomplete-paper > .Mui-selected {
    background-color: #8d724d !important;
  }

  .clear_btn_media {
    background: #202020 !important;
    min-width: auto !important;
    position: absolute !important;
    top: -15% !important;
    right: -1% !important;
    color: #bd9a68 !important;
    border: 1px solid !important;
    border-radius: 8px !important;
    padding: 0 6px !important;
  }

  .advert_field {
    position:  relative !important;
  }
