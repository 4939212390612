.testi_container {
    background: #FEFFFB !important;
}

.title_testi{
    color: var(--heading, #202020);
    text-align: center;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.desc_testi {
    color: var(--heading, #202020);
    text-align: center !important;
    font-family: Vidaloka, serif !important;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 129.167% */
    text-transform: uppercase;
}

.text_svg {
    color: #202020;

/* Text */
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 186.667% */
}

.text2_svg {
    color: #BD9A68 !important;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}


@media screen and (max-width: 800px)  {
    .svg_testimonial {
        width: 400px !important;
    }
}

@media screen and (max-width: 610px) {
    .svg_testimonial {
        width: 350px !important;
    }
    .testi_avatar {
        width: 50px !important;
        height: 50px !important;
    }
}

@media screen and (max-width: 450px)  {
    .desc_testi {
        font-size: 35px !important;
    }

    .svg_testimonial {
        width: 300px !important;
    }
}


