.box_admin_panel {
    height: 100%;
    background: #161616;
    overflow: hidden;
}

.container_admin_panel_1_layer {
    background: #161616;
    border-radius: '10px';
    height: 100%;
    overflow: auto;
}

.container_admin_panel_2_layer {
    transition: .3s !important;
    height: 100%;
}

.container_admin_panel_3_layer {
    transition: .3s !important;
    background: #0f0f0f;
    border-radius: 10px 10px 0 0;
    height: 100% !important;
}

.circular_blog {
    justify-content: center;
    align-items: center;
}

.container_menu_bar {
    transition: .3s !important;
}

.menu_bar_hide {
    max-width: 75px !important;
}

@media screen and (max-width: 900px){
    .container_menu_bar {
        position: fixed !important;
        height: 100%;
        z-index: 2;
        display: block;
    }

    .menu_bar_hide {
        transition: 1.3s !important;
        transform: translate(-400px, 0) !important;
    }
}