a {
    font-family: Montserrat, serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: white !important;
    text-decoration: none !important;
    transition: opacity .2s ease-in-out;
}

span.MuiTypography-root {
    font-family: Arial, serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
}

a:hover{
    text-decoration: underline !important;
}


.MuiGrid-container {
    display: flex;
    align-items: flex-end;
    padding: 0 40px 5px;
    margin-left: 0 !important;
}

.navGridWrap {
    align-self: center;
    background: transparent;
    width: inherit !important;
}

.navGridWrap > div{
    background: transparent;
    align-items: baseline;

}

header {
    position: absolute !important;
    background: transparent !important;
    box-shadow: none !important;
    height: 70px;
    padding: 0;
}

.reland_link{
    font-size: 30px !important;
    font-family: Vidaloka, serif;
}


