.container_news {
    background: var(--background-secondary, #F9FAF1);
}

.from_our_blog {
    color: var(--heading, #202020);
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.latest_news_events {
    color: var(--heading, #202020);
    font-family: Vidaloka, serif !important;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 62px; /* 129.167% */
    text-transform: uppercase;
}

.news_button {
    border-radius: 8px;
    border: 1.3px solid var(--primary, #BD9A68) !important;
    color: var(--primary, #BD9A68) !important;

    /* Button Text */
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
    padding: 18px 40px !important;
}

.title_img {
    color: var(--heading, #202020);
    font-family: Vidaloka, serif !important;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 145.455% */
    text-transform: uppercase;
}

.desc_img {
    color: var(--text, #797978);
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.container_question {
    border-radius: 16px;
    background: var(--background-primary, #FEFFFB);
}

.quick_text {
    color: var(--heading, #202020);
    text-align: center;
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.if_you_text {
    color: var(--heading, #202020);
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 142.857% */
    text-transform: uppercase;
}

#outlined-b1, #outlined-b2 {
    width: 332px !important;

}

.container_text_field > div > div > div > fieldset {
    border: 1px solid #BD9A68 !important;
}
.container_text_field > div > div > div > fieldset > legend {
    color: #BD9A68 !important;
}

.container_text_field > div > div > label {
    color: #828282 !important;
}

.button_submit {
    border-radius: 8px !important;
    background: var(--primary, #BD9A68) !important;
    padding: 18px 40px !important;
    color: var(--off-heading, #FBFBFB) !important;
    /* Button Text */
    font-family: Montserrat, serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

/*.sw {*/
/*    padding-bottom: 0px !important;*/
/*}*/


.accept_terms_text {
    padding-bottom: 5px !important;
    color: var(--heading, #202020);
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}



@media screen and (max-width: 450px) {
    .news_image {
        width: 350px !important;
        height: 250px !important;
    }
}
