.draw_dialog > div > .MuiDialog-paper {
    background: #282828 !important;
    border: 1px solid #bd9a68;
    position: relative;
}

.navigate_dialog_btn {
    background: #bd9a68 !important;
    color: white !important;
}

.navigate_dialog_dark_btn {
  background: #0F0F0F !important;
  color: #bd9a68 !important;
  min-width: auto !important;
}

.sort_component > ul,
.sort_component > li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
}

.sort_component > ul {
  position: relative;
  width: 300px;
}

.sort_component > li {
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 15px 18px;
  /* position: relative; */
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  cursor: grab;
}

.sort_component > li svg {
  width: 18px;
  height: 18px;
  cursor: grab;
}

.no_content_block > p {
  color: #4e4e4e !important;
  font-size: 50px !important;
  font-weight: 800 !important;
}

.navigation_sort_images_btn_type_1 {
  background: #bd9a68 !important;
  color: white !important;
}

.navigation_sort_images_btn_type_2 {
  background: #0F0F0F !important;
  color: #bd9a68 !important;
  padding: 0px 15px !important;
  border-radius: 8px !important;
}

.no_content_sort_image {
  background: #212121 !important;
  border-radius: 5px !important;
}

.sort_container {
  border: 1px solid #bd9a68;
  border-radius: 5px;
}

.img_blog_drag_card {
  background-size: contain;
  width: -webkit-fill-available;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.card_drag {
  background: #161616 !important;
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  border-radius: 8px !important;
  align-content: center !important; 
  min-height: 168px;
}

.btn_close_dialog {
  background: #161616 !important;
  border: 1px solid #bd9a68 !important;
  border-radius: 50% !important;
  padding: 2px 10px !important;
  color: #bd9a68 !important;
  transition: .5s !important;
  position: absolute !important;
  min-width: fit-content !important;
  right: 15px;
  top: 3px;
}

.btn_close_dialog:hover {
  scale: 1.1;
}