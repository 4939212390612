.input_quick_inquiry > div {
    width: 95%;
}

.input_quick_inquiry {
    position: relative;
}

/* .input_quick_inquiry > div > div > fieldset, .textarea_quick_inquiry > div > div > fieldset {
    border: 1px solid #bd9a68 !important;
} */

.MuiSwitch-track {
    background-color: #bd9a68 !important;
}


@media screen and (max-width: 1200px) {
    .input_quick_inquiry{
        margin: 5px 0 !important;
        text-align: center;
    }

    .btn_submit_quick_inquiry_div {
        margin-top: 10px !important;
        text-align: center !important;
    }
}

