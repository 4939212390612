.success_reg_container {
    height: 100vh;

}

.success_reg_svg {
    color: #468a47;
    font-size: 120px !important;
}

.success_reg_h1 {
    font-size: 25px !important;
}


.success_reg_wrap_container {
    box-shadow: 1px 1px 12px 3px #8080809c;
    border-radius: 8px;
    padding: 50px ;
}