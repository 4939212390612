.swipe_drawer > .MuiPaper-root {
    background-color: white !important;
}

.MuiDrawer-root > .MuiCollapse-root > .MuiListItemButton-root > .MuiTypography-root {
    color: #212529 !important;
}

@media screen and (max-width:1000px) {
    .navbarMenuButton {
        display: block ;
    }

    .menuLinks {
        display: none;
    }

    .menuTextReland {
        display: none;
    }
}

@media screen and (min-width: 1000px) {
    .navbarMenuButton {
        display: none !important;
    }
    .BoxNavBar_absolute {
        height: 115px;
    }
}

.navbar_reland {
    color: var(--primary, #BD9A68);
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 39px; /* 130% */
    text-transform: uppercase;
}

.navbar_text {
    color: var(--heading, #202020) !important;
    text-align: center;

    /* Button Text */
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.list_content > .MuiButtonBase-root > .MuiListItemText-root > span {
    color: #212529 !important;
}

.navbar_svg {
    transform: scale(-1, 1) !important;
}

.menuTextReland {
    text-align: center;
    font-family: Vidaloka, serif !important;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 39px; /* 130% */
    text-transform: uppercase;
}

.menuLinks {
    font-family: Montserrat, serif !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
    transition: 0.2s !important;;
}

.menuLinks:hover {
    cursor: pointer;
    text-decoration: none !important;
    color: #BD9A68 !important;
    font-weight: 500 !important;
    transition: 0.2s !important;
    font-size: 16px !important;
;
}

.button_turn_back {
    position: fixed !important;
    min-width: 35px !important;
    transition: 1s !important;
    right: 20px !important;
    bottom: -50px !important;
    background: #bd9a68 !important;
    color: white !important;
}

.show_turn_back {
    bottom: 30px !important;
    z-index: 1000 !important;
}

.button_turn_back {
    border-radius: 8px !important;
    padding: 1px 5px !important;
}

.button_turn_back > svg {
    min-width: none;
}

.button_turn_back:hover{
    transition: 1s;
    background: #212529 !important;
}

.button_turn_back > span{
    transition: 1s !important;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

.BoxNavBar {
    z-index: 3 !important;
    background: white !important;
    position: fixed !important;
    transition: .8s !important;
    height: 125px;
    box-shadow: 0 8px 8px 0 rgba(34, 60, 80, 0.2) !important;
    justify-content: center;
}

.BoxNavBar_absolute {
    position: absolute !important;
    z-index: 3 !important;
    transition: .8s !important;
    justify-content: center;
}

.dark_mode {
    background: #000000a6 !important;
}

.menuLinks_white_mode {
    color: #202020 !important;
}

.menuLinks_tran_mode {
    color: white !important;
}

.menuLinks_pointed_mode {
    color: #BD9A68 !important;
}

.toolbar_card {
    position: absolute;
    background-color: #202020 !important;
    height: 0;
    display: flex;
    transition: .2s !important;
    margin-top: 25px;
    border-radius: 8px !important;
    transition-delay: 0.2s !important;
}

.menuLinks:hover .toolbar_card,  .toolbar_card:hover{
    transition: .2s !important;
    height: 150px !important;
}

.toolbar_card_content {
    display: flex !important;
    flex-flow: column;
}

.toolbar_card_content_link {
    padding: 8px 10px 8px 10px;
    line-height: 1.4;
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #9d9b9b !important;
    text-transform: capitalize !important;
    transition: 0.2s;
}

.toolbar_card_content_link:hover {
    color: #D7D7D7FF !important;
}


