.number_card_text {
    font-size: 45px !important; 
    border: 5px dashed #212121;
    width: fit-content;
    padding: 5px 25px !important;
    margin: auto !important;
    font-weight: 900 !important;
    color: #212121;
}

.cross_btn {
    position: absolute !important;
    top: 0 !important;
    right: 0px !important;
    background: #8b724f !important;
    color: #212121 !important;
    min-width: auto !important;
    padding: initial !important;
    border-radius: 0px 1px 0px 5px !important;
}

.upload_btn {
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    opacity: .7 !important;
    margin-top: 10px !important;
    padding: 0px !important;
    background: #161616 !important;
    box-shadow: none !important;
}

.add_svg {
    color: #000 !important;
    fill: #bd9a68 !important;
    margin-right: 3px;
}

img {
    pointer-events: none;
    object-fit: contain;
}

.slick-slider {
    width: -webkit-fill-available
}

.slick-slide {
    text-align: -webkit-center;
}

