




/* Button*/

.btn {
    user-select: none;
    -moz-user-select: none;

    border-radius: 8px;
    color: var(--tg-white);
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 1rem;
    margin-bottom: 0;
    padding: 19px 38px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    transition: background .6s cubic-bezier(.55, 0, .1, 1), border .6s cubic-bezier(.55, 0, .1, 1);
}

.btn_m {
    position: relative;
    overflow: hidden;
    height: 1rem;
}

.btn_c {
    transition: transform .6s cubic-bezier(.55, 0, .1, 1);
}

.btn_t1 {
    transform-origin: 100%;
    transition: transform .45s cubic-bezier(.55, 0, .1, 1), color .6s cubic-bezier(.55, 0, .1, 1);
    display: block;
}

.btn_t2 {
    transform-origin: 0;
    transition: transform .45s cubic-bezier(.55, 0, .1, 1), color .6s cubic-bezier(.55, 0, .1, 1);
    display: block;
    transform: rotate(40deg);
}

.btn_t2:hover, .btn_t1:hover{
    text-decoration: none !important;
}

.btn:hover .btn_c {
    transform: translateY(-1rem);
}

.btn:hover .btn_t1 {
    transform: rotate(40deg);
    text-decoration: none !important;
}

.btn:hover .btn_t2 {
    transform: rotate(0);
}


a:hover {
    text-decoration: none !important;
}

.btn_color {
    background: var(--primary, #BD9A68) !important;
    border: 1.3px solid var(--tg-primary-color);
}

.btn_tran {
    background: white !important;
    border: 1.3px solid #BD9A68;
    color: #BD9A68 !important;
}

.btn_color:hover {
    background: #212529 !important;
    border-color: #212529 !important;
}

.btn_tran:hover {
    background: var(--primary, #BD9A68) !important;
    color: white !important;
}

.banner-scroll-down {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 50px;
}
.banner-scroll-down a {
    color: white;
    display: block;
    width: 30px;
    height: 50px;
    text-align: center;
}
.banner-scroll-down a span {
    display: block;
    width: 15px;
    height: 15px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    margin: -10px 0 0 8px;
    animation: animate 2s infinite;
}
.banner-scroll-down a span:nth-child(2) {
    animation-delay: -0.2s;
}
.banner-scroll-down a span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-15px, -15px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(15px, 15px);
    }
}

.banner-area-two .banner-scroll-down {
	position: absolute;
	left: 8%;
	transform: translate(0);
	bottom: 3%;
}
.banner-area-two .banner-scroll-down a {
	color: var(--tg-secondary-color);
}
.banner-area-two .banner-scroll-down a span {
    border-color: var(--tg-secondary-color);
}

.accept_terms_span {
    color: var(--heading, #202020);
    font-family: Montserrat, serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

@media screen and (max-width: 480px) {
    .banner-scroll-down > a > span {
        width: 12px !important;
        height: 12px !important;
    }
}
